import { useState, useEffect, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import * as Dialog from '@radix-ui/react-dialog';
import LinearProgress from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import mailBoxImage from '../../../assets/vectors/mail_box2.svg';
import sendingEmailAnimation from '../../../assets/lotties/sending-email.json';

import { useRouter } from '../../../hooks/useRouter';
import { useAuthContext } from '../../../contexts/Auth0Context';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import { Urls } from '../../urls';
import generateID from '../../../helper/id';
import { formatUrl, formatGoogleLogin, formatLinkedin } from '../../../utils/url';
import { LoginWrapper } from './Login.styles';

import { LayoutCentered } from '../../../components/atoms/Layout/Layout';
import { PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { H1 } from '../../../components/atoms/Typography/Headings';
import { ModalOverlay, ModalContent } from '../../../components/atoms/Modal';
import { Placeholder } from '../../../components/atoms/Placeholder';
import { Copy } from '../../../components/atoms/Typography';
import { Link } from '../../../components/atoms/Button';
import { TabController, TabPanel } from '../../../components/atoms/Tabs';
import { SocialLogin } from '../../../components/molecules/SocialLogin';
import { Lottie } from '../../../components/atoms/Lottie';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { Form } from '../../../components/atoms/Form/Form';
import { TextInput } from '../../../components/atoms/Form/TextInput';
import { CopyColoredSpan } from '../../../components/atoms/Typography/Copy';

export const Login = () => {
  const timerRef = useRef<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [state, setState, deleteState] = useLocalStorage('JOBPOD_STATE', '');
  const [email, setEmail] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const { passwordlessSignIn, isAuthenticated } = useAuthContext();
  const {
    navigate,
    query: { redirect = '' } = {},
    routerState,
    location: { hash },
  } = useRouter();

  const redirection = routerState?.from ? routerState?.from : redirect;

  const parsedHash = new URLSearchParams(hash.substring(1));
  const accessToken = parsedHash.get('access_token');

  // http://localhost:5173/login?redirect=/boards#access_token=eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly90YXJnb28uZXUuYXV0aDAuY29tLyJ9..KVbRg6ojf8Epf_hR.msHr9AWgSwmdew4zytWhp79JYscvTj8jYTA1q972yyOeuPWJqoSD2bP6sl6Yyy2Cko78qT5ffpPVsBL89wjTCRaFfvjRb-hLaLlqkcK6_2P-MI0SudXEC52sSu9aSLtgu_BfzAW6NupiAKliJw92kbuNMFcyAIQ0Dfsm-GUrJBOhYV22B-wyNLfwUuggaW-xX1y8zyeKNiFhAvqux7KqBVJ6JZnALqlYbm-PNY6pf8mZUY50BxYiaMpX90oRC-EP7rza_0gFi2jxh31ANJphgBel3fc6ZNZZ1ihU2dxuZ2GdlBiJthz8L5AKtg.9AIRxqpF0B3anCoc1Ij2Ww&scope=openid%20profile%20email&expires_in=7200&token_type=Bearer&state=z7duvAaehPvLm56R

  useEffect(() => {
    if (!isVisible) {
      setProgress(0);
      clearInterval(timerRef.current);
      return;
    }
    timerRef.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setIsVisible(false);
          window.location.reload();
        }
        const diff = 1;
        return Math.min(oldProgress + diff, 100);
      });
    }, 250);

    return () => {
      setProgress(0);
      clearInterval(timerRef.current);
    };
  }, [isVisible]);

  useEffect(() => {
    // On mounting we generate a random state and store it in localStorage.
    setState(generateID());

    if (redirection) {
      setLoading(true);
    }

    if (isAuthenticated) {
      deleteState();
      if (redirection) {
        navigate(redirection);
      } else {
        navigate(formatUrl(`/${Urls.Dashboard}`));
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setIsVisible(true);
      setEmail(data.email);
      await passwordlessSignIn({
        variables: {
          state,
          email: data.email,
          redirectUri: `${import.meta.env.VITE_APP_URL}/${Urls.Login}?redirect=${redirection}`,
        },
      });
    } catch (error) {
      console.error(error);
      setIsVisible(false);
      setEmail('');
      toast.error('Sorry, something went wrong. Please try again later.');
    }
  };

  return (
    <LayoutCentered>
      {accessToken ? (
        <Placeholder
          title="Loading..."
          description={
            <>
              <Copy marginBottom={10}>Hang on tight</Copy>
            </>
          }
        />
      ) : (
        <LoginWrapper>
          <TabController
            headers={[
              { id: 0, label: 'Magic Link' },
              { id: 1, label: 'Social' },
            ]}
          >
            <TabPanel $tabId={0}>
              <H1>Signup / Login</H1>
              <Form onSubmit={onSubmit} validationSchema={validationSchema}>
                <TextInput name="email" defaultValue="" type="email" label="" />
                <Copy styleLevel={2} marginBottom={40}>
                  We&rsquo;ll email you a magic link to sign in to{' '}
                  <strong>
                    JobsBoard
                    <CopyColoredSpan>.io</CopyColoredSpan>
                  </strong>
                </Copy>
                <Copy marginBottom={40} styleLevel={3}>
                  By continuing you agree to{' '}
                  <Link to={`/static/${Urls.TermsOfUse}`}>JobsBoard&rsquo;s Terms of Use</Link> &{' '}
                  <Link to={`/static/${Urls.PrivacyPolicy}`}>Privacy Policy</Link>.
                </Copy>
                <PrimaryButton type="submit" disabled={isVisible} busy={isVisible}>
                  Submit
                </PrimaryButton>
              </Form>
            </TabPanel>
            <TabPanel $tabId={1}>
              <H1>Social Login</H1>
              <SocialLogin connection="google" url={formatGoogleLogin(state, redirection)} />
              <SocialLogin connection="linkedin" url={formatLinkedin(state, redirection)} />
              <Copy marginBottom={40} styleLevel={3}>
                By continuing you agree to <Link to={`/static/${Urls.TermsOfUse}`}>JobsBoard&rsquo;s Terms of Use</Link>{' '}
                & <Link to={`/static/${Urls.PrivacyPolicy}`}>Privacy Policy</Link>.
              </Copy>
            </TabPanel>
          </TabController>
          <Spacer y={100} />
        </LoginWrapper>
      )}

      <Dialog.Root open={isVisible}>
        <Dialog.Portal>
          <ModalOverlay>
            <ModalContentExtented>
              <Placeholder
                lottie={<Lottie animationData={sendingEmailAnimation} />}
                icon={mailBoxImage}
                iconAlt="mail box"
                title="Check Your Email"
                description={
                  <>
                    <Copy styleLevel={2} marginBottom={0}>
                      We&rsquo;ve sent a login link to:
                    </Copy>
                    <Copy styleLevel={2} marginBottom={8}>
                      {email}
                    </Copy>
                    <Copy styleLevel={3} marginBottom={16}>
                      Click on the temporary link in the email to log in or sign up
                    </Copy>
                  </>
                }
              />
              <LinearProgress variant="determinate" value={progress} />
            </ModalContentExtented>
          </ModalOverlay>
        </Dialog.Portal>
      </Dialog.Root>
    </LayoutCentered>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('The email is not valid').required('The email is required'),
});

type FormData = Yup.InferType<typeof validationSchema>;

const ModalContentExtented = styled(ModalContent)`
  max-width: 400px;
`;
