import React from 'react';
import styled from 'styled-components';
import { GoogleLoginButton, LinkedInLoginButton } from 'react-social-login-buttons';

export const SocialLogin = ({ connection, url }: SocialLoginProps) => {
  switch (connection) {
    case 'google':
      return (
        <Container>
          <GoogleLoginButton onClick={() => handleClick(url)} />
        </Container>
      );
    case 'linkedin':
      return (
        <Container>
          <LinkedInLoginButton onClick={() => handleClick(url)} />
        </Container>
      );
    default:
      return <Container></Container>;
  }
};

const Container = styled.a`
  padding: 10px;
`;

function handleClick(url: string) {
  if (typeof window === 'undefined') {
    return;
  }
  window.location = url;
}

export interface SocialLoginProps extends React.HTMLAttributes<HTMLDivElement> {
  connection: string;
  url: string;
}
